import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import Button from "../../../UI/Button"
import { useState } from "react"
import ConatctShareDropdown from "../../../UI/ShareDropdown"

const InviteToChat = () => {

    const contactInfo = useSelector(state => state.contacts.contactInfo)
    const loading = useSelector(state => state.contacts.loading)

    const [showShareDropdown, setShowShareDropdown] = useState(false)

    const showShareBox = () => {

        if(!navigator.share){
            // show share dropdown
            setShowShareDropdown(true)
            document.body.classList.add('modal-opened')
        }
        else {
            navigator.share({
                title: 'اشتراک گذاری برنامه با',
                url: 'www.client.bavakil.net',
            }).then(() => {
            }).catch(error => {
            })
        }

    }

    return (
        <>
        
            <div className="flex items-center justify-center h-60">
                
                <div className="bg-white p-4 shadow-custom-md min-w-[250px] rounded-xl flex flex-col items-center justify-center gap-4">
                    
                    <ReactSVG 
                        className="w-12 text-primary"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/share.svg`}
                    />

                    { !loading ? 
                        <h3> دعوت از {contactInfo.name} </h3>
                        :
                        <h3 className="flex items-center gap-1"> 
                            دعوت از
                            <ReactSVG 
                                className="w-4 text-gray-500"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            />
                                    
                        </h3>
                    }

                    <Button onClick={showShareBox} className={'py-2 px-6 w-full rounded-md'}> دعوت </Button>

                </div>

            </div>
                    
            { !navigator.share && showShareDropdown &&
                <ConatctShareDropdown 
                    name={contactInfo.name}
                    show={showShareDropdown}
                    setShow={setShowShareDropdown}
                />
            }

        </>
    )

}

export default InviteToChat