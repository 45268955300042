import { useLocation, useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { checkExistAuthStep1 } from "../../../../utils/auth"

const AuthBackBtn = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const isTWA = () => {
        // Check if display-mode is standalone (used for TWA)
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        
        // Additional check for userAgent (optional, based on common patterns)
        const isAndroid = navigator.userAgent.includes('Android');
        const isInTWA = navigator.userAgent.includes('TWA');
    
        // If either condition is true, consider it TWA
        return isStandalone || (isAndroid && isInTWA);
    };

    const handleBackStep = () => {
        
        if(location.pathname === '/auth/verify'){
            const isInputedPhone = checkExistAuthStep1()

            if(isInputedPhone?.mobile){
                navigate('/auth/login')
            }
            else if(!isTWA())
                navigateToSelectClient()

        }
        else if(!isTWA())
            navigateToSelectClient()

    }

    const navigateToSelectClient = () => {
        
        window.location.href = 'https://bavakil.net/select-client-type'
    
    }

    if( (location.pathname === '/auth/verify') || ( !isTWA() && (location.pathname === '/auth/login')) ){

        return (
            <div className="mb-4">

                <button onClick={handleBackStep} className="absolute top-4 hover:border-gray-300 left-4 md:top-8 lg:left-20 lg:top-10 md:left-12 w-10 h-10 bg-white flex items-center justify-center shadow-custom-lg rounded-xl transition-colors border duration-300 text-gray-700 hover:text-primary border-gray-200 z-10">
                    
                    <ReactSVG 
                        className="w-5"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`}
                    />

                </button>

            </div>
        )

    }

    else {
        return (<></>)
    }

}

export default AuthBackBtn